import { getAuthenticityToken } from "../lib/Authenticity";

export const post = (path: string, params: any, method = 'post', with_authenticity: boolean = true) => {

  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = `user[${key}]`;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  if (with_authenticity) {
    const authField = document.createElement('input');
    authField.type = 'hidden';
    authField.name = "authenticity_token";
    authField.value = getAuthenticityToken() || ""
    form.appendChild(authField);
  }

  document.body.appendChild(form);
  form.submit();
};
